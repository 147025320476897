export const getGlobalAssetTypes = `
query GetGlobalAssetTypes {
  getGlobalAssetTypes {
    id
    tenant
    name
    manufacturer
    image
  }
}
`;
export const getCustomerConnectableAssets = `
query GetCustomerConnectableAssets($tenant: String!) {
  getCustomerConnectableAssets(tenant: $tenant) {
    name
    assetId
  }
}
`;
export const getCustomerConnectableGreengrass = `
query GetCustomerConnectableGreengrass($tenant: String!) {
  getCustomerConnectableGreengrass(tenant: $tenant) {
    coreDeviceThingName
  }
}
`;
export const getCustomerDisconnectableAssets = `
query GetCustomerDisconnectableAssets($tenant: String!) {
  getCustomerDisconnectableAssets(tenant: $tenant) {
    name
    assetId
  }
}
`;
export const searchAwsResources = `
query SearchAwsResources($tenant: String!, $assetId:String) {
  searchAwsResources(tenant: $tenant, assetId: $assetId){
    ResourceArn
    ResourceType
  }
  searchChinaAwsResources(tenant: $tenant, assetId: $assetId){
    ResourceArn
    ResourceType
  }
}
`;

export const getAssetsMonitoringInfo = `
query getAssetsMonitoringInfo {
  getAssetsMonitoringInfo
  {
    tenant
    siteId
    assetId
    name
    lastOnline
    lastProductionChange
    lastVibrationChange
    lastOpcUaChange
    ingestionStatus{
      s3
      dynamo
      influx
    }
    monitoringAlarmUser
    monitoringFavoriteUser
  }
}
`;
