

















































import { format, isValid } from "date-fns";
import { DataTableHeader } from "vuetify";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Customer, CustomerV2, Site, Tenant } from "@/customers/models";
import CustomerAdd from "@/customers/components/CustomerAdd.vue";
import CustomerDetails from "@/customers/components/CustomerDetails.vue";
import CustomerAddEdit from "@/customers/components/CustomerAddEdit.vue";
import OsdiButton from "@/../shared-ui/components/Osdi/OsdiButton.vue";
import OsdiTable from "@/../shared-ui/components/Osdi/OsdiTable.vue";
import DialogStore from "@/store/modules/dialog-store";
import CustomerStore from "@/store/modules/customer-store";

const dialogStore = getModule(DialogStore);

@Component({
  components: { OsdiButton, CustomerDetails, CustomerAdd, OsdiTable, CustomerAddEdit },
})
export default class CustomerList extends Vue {
  private customerStore = getModule(CustomerStore);
  private search = "";
  private customerOpen = false;
  private customerAddOpen = false;

  private tableHeaders: DataTableHeader[] = [
    {
      text: this.$t("customers.customerList.tenantName").toString(),
      value: "tenant.name",
    },
    {
      text: this.$t("customers.customerList.siteName").toString(),
      value: "site.name",
    },
    {
      text: this.$t("customers.customerList.customerNumber").toString(),
      value: "site.customerNumber",
    },
    {
      text: this.$t("customers.customerList.address").toString(),
      value: "site.location.address",
    },
    {
      text: this.$t("customers.customerList.signUpDate").toString(),
      value: "site.signupDate",
    },
  ];

  selectedCustomer: CustomerV2 | undefined;

  async mounted() {
    await this.loadCustomers();
  }

  timestampToLocalDateString(aTimestamp: number): string {
    if (!isValid(aTimestamp)) return "";
    return format(aTimestamp, "dd.MM.yyyy");
  }

  openCustomerDetails(customer: CustomerV2 | undefined) {
    this.selectedCustomer = customer;
    this.customerOpen = true;
  }

  closeCustomerDetails() {
    this.customerOpen = false;
  }

  openCustomerAdd() {
    this.customerAddOpen = true;
  }
  closeCustomerAdd() {
    this.customerAddOpen = false;
  }

  get customerTenant() {
    return this.$route.name === "CustomerDetails" ? this.$route.params?.tenant : "";
  }

  async loadCustomers() {
    this.customerStore.fetchCustomers();
  }
}
