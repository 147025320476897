























import { Component, Vue, Prop } from "vue-property-decorator";
import OsdiButton from "@/../shared-ui/components/Osdi/OsdiButton.vue";

@Component({
  components: { OsdiButton },
})
export default class OsdiIconButton extends Vue {
  console = console;
  @Prop({ default: false, type: Boolean }) hasTooltip!: boolean;

  eventHandlers(
    listeners: Record<string, Function | Function[]>,
    on: any
  ): Record<string, Function | Function[]> {
    return Object.assign({}, listeners, on);
  }
}
