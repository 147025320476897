





































































































import { Component, Vue } from "vue-property-decorator";
import AssetCerts from "@/assets/components/AssetCerts.vue";
import AssetClientCerts from "@/assets/components/AssetClientCerts.vue";
import AssetApiKey from "@/assets/components/AssetApiKey.vue";
import OsdiButton from "@/../shared-ui/components/Osdi/OsdiButton.vue";
import OsdiSnackbar from "@/../shared-ui/components/Osdi/OsdiSnackbar.vue";
import type {
  ApigatewayAssetConnectionResult,
  Asset,
  CoreInfo,
  GreengrassAssetConnectionResult,
  GreengrassAddAssetConnectionResult,
} from "../models";
import {
  connectAssetApi,
  connectAssetAddGreengrass,
  connectAssetV2,
  connectChinaAsset,
  getCustomerConnectableAssets,
  getCustomerConnectableGreengrass,
} from "@/assets/asset-service";
import { service as customerService, CustomerTenant } from "@/customers/api";

export interface FormModel {
  assetId: string;
  tenant: string;
  coreName: string;
  connectivity: "api" | "mqtt" | "china" | "greengrassexist";
}

@Component({
  components: { AssetCerts, AssetClientCerts, AssetApiKey, OsdiButton, OsdiSnackbar },
})
export default class ConnectAsset extends Vue {
  private payload: Partial<FormModel> = {
    assetId: undefined,
    tenant: undefined,
    coreName: undefined,
    connectivity: undefined,
  };
  private requestInProgress = false;
  private assetCerts:
    | GreengrassAssetConnectionResult
    | GreengrassAddAssetConnectionResult
    | undefined
    | null = null;
  private assetClientCerts: GreengrassAddAssetConnectionResult | undefined | null = null;
  private customers: CustomerTenant[] = [];
  private assets: Asset[] = [];
  private cores: CoreInfo[] = [];
  private assetApiKey: ApigatewayAssetConnectionResult | undefined | null = null;
  private snackbarMessage: string = "";
  private linktoapp: string = "";

  get areFieldsFilled(): boolean {
    var allFieldsAreFilled: boolean = false;
    const result1: boolean =
      !!this.payload.assetId && !!this.payload.tenant && !!this.payload.connectivity;
    if (this.payload.connectivity === "greengrassexist")
      allFieldsAreFilled = !!this.payload.coreName && !!result1;
    else allFieldsAreFilled = result1;
    return allFieldsAreFilled;
  }

  async mounted() {
    this.customers = await customerService.fetchTenants();
    this.linktoapp = await customerService.getPresignedurlforApp();
  }

  private async onTenantSelected(theTenant: string) {
    this.assets = [];
    this.payload.assetId = undefined;
    this.assets = await getCustomerConnectableAssets(theTenant);
    if (this.payload.connectivity === "greengrassexist") {
      this.cores = [];
      this.payload.coreName = undefined;
      this.cores = await getCustomerConnectableGreengrass(this.payload.tenant!);
    }
  }

  private async connectAsset() {
    const { assetId, tenant, connectivity, coreName } = this.payload;
    try {
      this.requestInProgress = true;
      switch (connectivity) {
        case "api":
          this.assetApiKey = await connectAssetApi(tenant!, assetId!);
          break;
        case "mqtt":
          this.assetCerts = await connectAssetV2(tenant!, assetId!);
          break;
        case "greengrassexist":
          this.assetClientCerts = await connectAssetAddGreengrass(tenant!, assetId!, coreName!);
          break;
        case "china":
          this.assetCerts = await connectChinaAsset(tenant!, assetId!);
          break;
        default:
          throw new Error("No connection type specified.");
          break;
      }
      //@ts-ignore
      this.$refs.snackbar.open();
      this.snackbarMessage = "Asset connected.";
    } catch (error) {
      //@ts-ignore
      this.$refs.snackbar.open();
      this.snackbarMessage = "Could not connect asset.";
    } finally {
      this.requestInProgress = false;
    }
  }

  private resetFields() {
    this.payload = {
      assetId: undefined,
      tenant: undefined,
      coreName: undefined,
      connectivity: undefined,
    };
  }
}
