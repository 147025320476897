









































import { CustomerTenant } from "@/customers/api";
import customerService from "@/customers/customer.service";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import {
  getCustomerConnectableAssets,
  getCustomerDisconnectableAssets,
} from "@/assets/asset-service";
import { Asset } from "../models";
@Component
export default class TenantAssetSelector extends Vue {
  private customers: CustomerTenant[] = [];
  private assets: Asset[] = [];
  private payload = {
    assetId: undefined,
    tenant: undefined,
  };

  @Prop()
  connected!: boolean;

  @Emit()
  tenantSelected(theTenant: string): string {
    return theTenant;
  }

  @Emit()
  assetSelected(theAsset: string): string {
    return theAsset;
  }

  async mounted() {
    this.customers = await customerService.fetchTenants();
  }

  async onTenantSelected(theTenant: string) {
    this.assets = [];
    this.payload.assetId = undefined;
    this.tenantSelected(theTenant);
    if (this.connected) {
      this.assets = await getCustomerConnectableAssets(theTenant);
    } else {
      this.assets = await getCustomerDisconnectableAssets(theTenant);
    }
  }
}
