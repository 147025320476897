



































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import OsdiIconButton from "@/../shared-ui/components/Osdi/OsdiIconButton.vue";

export interface OsdiTableRowAction {
  tooltip: string;
  icon: string;
  callback: any;
}

@Component({
  components: { OsdiIconButton },
})
export default class OsdiTable extends Vue {
  @Prop()
  public actions?: OsdiTableRowAction[];

  private options = {
    itemsPerPage: 50,
  };

  private get tableHeaders() {
    if (this.$attrs["loading"]) {
      // workaround to make the loading indicator visible
      return [{}];
    }

    const tableHeaders = [];
    const headers = this.$attrs["headers"] as any;
    tableHeaders.push(...headers);
    //tableHeaders.push({ value: "actions" });
    return tableHeaders;
  }
}
