const awsconfig = {
  Auth: {
    region: process.env.VUE_APP_AWS_REGION,
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_USER_POOL_CLIENT_ID,
    identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID!,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.VUE_APP_S3_BUCKETNAME_SMART_COLLEGE!,
      region: process.env.VUE_APP_AWS_REGION!,
    },
  },
  API: {
    region: process.env.VUE_APP_AWS_REGION,
    aws_appsync_authenticationType: process.env.VUE_APP_AWS_APPSYNC_AUTH_TYPE!,
    aws_appsync_region: process.env.VUE_APP_AWS_REGION,
  },
  awsAppsyncRegion: process.env.VUE_APP_AWS_REGION!,
};

export default awsconfig;
