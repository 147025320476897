export const getCustomers = `
query GetCustomers {
  getCustomers {
    name
    address
    initialAdminEmail
    tenant
    signupDate
  }
}
`;

export const getCustomer = `
query GetCustomer($tenant:String!) {
  getCustomer(tenant:$tenant) {
    name
    address
    initialAdminEmail
    tenant
    signupDate
  }
}
`;

export const getTenants = `
query GetTenants {
  getTenants {
    tenant,
    name,
    additionalInfo,
    contactEmail
  }
}
`;

export const getAllSites = `
query GetAllSites {
  getAllSites {
    tenant
    id
    name
    additionalInfo
    location {
      address
      longitude
      latitude
    }
    customerNumber
    adminEmail
    status
    licenses {
      productName
      licenseType
      expiryDate
      orderReference
      status
      admin{
        userId
        email
        firstName
        lastName
        invitationSent
        language
      }
      previousAdmin{
        userId
        email
        firstName
        lastName
        invitationSent
        language
      }
    }
    signupDate
  }
}
`;
export const getPresignedUrlApp = `
query GetPresignedUrlApp {
  getPresignedUrlApp 
}
`;
