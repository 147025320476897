var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.$t("assets.monitoring.headline")))]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id"},scopedSlots:_vm._u([{key:"item.ingestionStatus.s3",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"centered"},[(item.ingestionStatus.s3)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close-circle ")])],1)]}},{key:"item.ingestionStatus.dynamo",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"centered"},[(item.ingestionStatus.dynamo)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close-circle ")])],1)]}},{key:"item.ingestionStatus.influx",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"centered"},[(item.ingestionStatus.influx)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close-circle ")])],1)]}},{key:"item.monitoringFavoriteUser",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"centered"},[(item.monitoringFavoriteUser)?_c('v-icon',{attrs:{"color":"gray"},on:{"click":function($event){return _vm.toggleFavorite(item)}}},[_vm._v(" mdi-star ")]):_c('v-icon',{attrs:{"color":"lightgray"},on:{"click":function($event){return _vm.toggleFavorite(item)}}},[_vm._v(" mdi-star ")])],1)]}},{key:"item.monitoringAlarmUser",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"centered"},[(item.monitoringAlarmUser)?_c('v-icon',{attrs:{"color":"gray"},on:{"click":function($event){return _vm.toggleAlarm(item)}}},[_vm._v(" mdi-bell ")]):_c('v-icon',{attrs:{"color":"lightgray"},on:{"click":function($event){return _vm.toggleAlarm(item)}}},[_vm._v(" mdi-bell ")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }