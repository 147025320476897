import { GraphQLAPIClass } from "@aws-amplify/api-graphql";
const anotherConfig = {
  region: process.env.VUE_APP_AWS_REGION,
  aws_appsync_authenticationType: process.env.VUE_APP_AWS_APPSYNC_AUTH_TYPE!,
  aws_appsync_region: process.env.VUE_APP_AWS_REGION,
  aws_appsync_graphqlEndpoint: process.env.VUE_APP_API_BACKOFFICE_URL!,
};

const API = new GraphQLAPIClass({});
API.configure(anotherConfig);

export default API;
