




































import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { SidebarItem } from "../../../interfaces/sidebarProps";
import DefaultLayoutSidebarItem from "./DefaultLayoutSidebarItem.vue";
import { LanguageSwitcherProps } from "../../../interfaces/language-switcherProps";

@Component({
  components: { DefaultLayoutSidebarItem },
})
export default class DefaultLayoutSidebarItems extends Vue {
  @Prop({ required: true }) readonly items?: SidebarItem[];
  @Prop({ default: false }) readonly sidebarIsActive?: boolean;
  @Prop() languageSwitcherProps?: LanguageSwitcherProps;
  private panels: Array<boolean> = [];

  @Watch("sidebarIsActive")
  sidebarIsActiveChanged(status: boolean) {
    if (!status) {
      this.resetPanels();
    }
  }

  resetPanels() {
    this.panels = new Array(this.items).map(() => false);
  }
  get languageSwitcher(): LanguageSwitcherProps {
    return {
      availableLanguages: this.languageSwitcherProps?.availableLanguages,
      onClickHandler: this.languageSwitcherProps?.onClickHandler,
    };
  }

  created() {
    this.resetPanels();
  }
}
