






import Vue from "vue";
import OsdiTextField from "@/../shared-ui/components/Osdi/OsdiTextField.vue";
import { Component } from "vue-property-decorator";

@Component({
  components: { OsdiTextField },
})
export default class OsdiMailField extends Vue {}
