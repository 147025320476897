





















import Vue from "vue";
import { Component, Emit } from "vue-property-decorator";
import OsdiButton from "@/../shared-ui/components/Osdi/OsdiButton.vue";
import lodash from "lodash";

@Component({
  components: {
    OsdiButton,
  },
})
export default class OsdiSnackbar extends Vue {
  private isOpen = false;
  private options!: OsdiSnackbarOptions;

  @Emit()
  public open(options: OsdiSnackbarOptions = {}) {
    this.options = {
      duration: 1000,
    };
    lodash.assign(this.options, options);
    this.isOpen = true;
  }

  @Emit()
  public close() {
    this.isOpen = false;
  }
}

export interface OsdiSnackbarOptions {
  duration?: number;
  message?: string;
  actionText?: string;
  action?(): void;
}
