import { AuthenticationState } from "@/authentication/auth-store";

export class AuthenticatorDisplayMap {
  showLogIn(authenticationState: AuthenticationState): boolean {
    return (
      authenticationState === AuthenticationState.signedOut ||
      authenticationState === AuthenticationState.signedIn
    );
  }
  showRequireNewPassword(authenticationState: AuthenticationState): boolean {
    return authenticationState === AuthenticationState.requireNewPassword;
  }
  showForgotPassword(authenticationState: AuthenticationState): boolean {
    return authenticationState === AuthenticationState.forgotPassword;
  }
}

export default new AuthenticatorDisplayMap();
