import { IVueI18n } from "vue-i18n";
import { SidebarItem } from "@/../shared-ui/interfaces/sidebarProps";
import { getModule } from "vuex-module-decorators";
import AuthStore from "@/authentication/auth-store";
import { signOut } from "@/authentication/authentication.service";

export default (i18n: IVueI18n) => ({
  top(): SidebarItem[] {
    return [
      {
        title: "Customers",
        icon: "mdi-account-group",
        to: "/customers",
      },
      {
        title: i18n.t("navigation.connect-asset.title").toString(),
        to: "/assets",
        icon: "mdi-connection",
      },
      {
        title: i18n.t("navigation.asset-disconnect.title").toString(),
        to: "/assetdisconnect",
        icon: " mdi-trash-can",
      },
      {
        title: i18n.t("navigation.infrastructure-resources.title").toString(),
        to: "/infrastrucure-resources",
        icon: "mdi-washing-machine",
      },
      {
        title: i18n.t("navigation.monitoring.title").toString(),
        to: "/monitoring",
        icon: "mdi-alert-outline",
      },
    ];
  },

  bottom(): SidebarItem[] {
    return [
      {
        title: "Logout",
        icon: "mdi-logout",
        onClick: async () => {
          const authModule = getModule(AuthStore);
          await signOut();
          authModule.logout();
        },
      },
    ];
  },
});
