










import Vue from "vue";
import { Component } from "vue-property-decorator";
import OsdiIconButton from "@/../shared-ui/components/Osdi/OsdiIconButton.vue";

@Component({
  components: { OsdiIconButton },
})
export default class OsdiCloseButton extends Vue {}
