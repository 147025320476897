import {
  Action,
  Module as VueXModule,
  Mutation,
  MutationAction,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";
import { AssetMonitoringInfo, IngestionStatus } from "@/assets/models";
import {
  setMonitoringAlarm,
  setMonitoringFavorite,
  getAssetsMonitoringInfo,
} from "@/assets/asset-service";

@VueXModule({ dynamic: true, store, name: "monitoring-store" })
export default class MonitoringStore extends VuexModule {
  assets: AssetMonitoringInfo[] = [];

  @Action({ commit: "changeAssetsMonitoringInfo" })
  async GetAssetsMonitoringInfo() {
    return await getAssetsMonitoringInfo();
  }

  @Mutation
  private async changeAssetsMonitoringInfo(assets: AssetMonitoringInfo[] | undefined) {
    if (assets) {
      this.assets = assets;
    }
  }

  //#region SetMonitoringFavorite
  @Action({ commit: "changeMonitoringFavorite" })
  async SetMonitoringFavorite({
    tenant,
    assetId,
    newValue,
  }: {
    tenant: string;
    assetId: string;
    newValue: boolean;
  }) {
    try {
      await setMonitoringFavorite(tenant, assetId, newValue);
      return { tenant, assetId, newValue };
    } catch (error) {
      throw Error(`Error in setMonitoringFavorite in store:  ${error}`);
    }
  }

  @Mutation
  private async changeMonitoringFavorite({
    tenant,
    assetId,
    newValue,
  }: {
    tenant: string;
    assetId: string;
    newValue: boolean;
  }) {
    const changeAsset = this.assets.find((asset: AssetMonitoringInfo) => {
      return asset.assetId === assetId && asset.tenant === tenant;
    });
    if (changeAsset) changeAsset.monitoringFavoriteUser = newValue;
  }

  //#endregion SetMonitoringFavorite

  //#region SetMonitoringAlarm
  @Action({ commit: "changeMonitoringAlarm" })
  async SetMonitoringAlarm({
    tenant,
    assetId,
    newValue,
  }: {
    tenant: string;
    assetId: string;
    newValue: boolean;
  }) {
    try {
      await setMonitoringAlarm(tenant, assetId, newValue);
      return { tenant, assetId, newValue };
    } catch (error) {
      throw Error(`Error in SetMonitoringAlarm in store:  ${error}`);
    }
  }

  @Mutation
  private async changeMonitoringAlarm({
    tenant,
    assetId,
    newValue,
  }: {
    tenant: string;
    assetId: string;
    newValue: boolean;
  }) {
    const changeAsset = this.assets.find((asset: AssetMonitoringInfo) => {
      return asset.assetId === assetId && asset.tenant === tenant;
    });
    if (changeAsset) changeAsset.monitoringAlarmUser = newValue;
  }
  //#endregion SetMonitoringAlarm
}
