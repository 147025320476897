
































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Asset } from "@/assets/models";
//import assetService from "@remote-api/asset.service";
@Component
export default class AssetDelete extends Vue {
  @Prop()
  asset!: Asset;

  private dialogVisible = false;

  @Emit("doDelete")
  private doDelete() {
    this.dialogVisible = false;
    return true;
  }
}
