
















































































import { Component, Vue, Prop } from "vue-property-decorator";
import { GreengrassAddAssetConnectionResult } from "../models";

@Component
export default class AssetClientCerts extends Vue {
  @Prop()
  assetCerts!: GreengrassAddAssetConnectionResult;

  private tab = null;
}
