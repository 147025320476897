
























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class OsdiButton extends Vue {
  @Prop({ default: "" }) icon!: string;
  @Prop({ default: false, type: Boolean }) iconOnly!: boolean;
  @Prop({ default: false, type: Boolean }) enabled!: boolean;
}
