import VueI18n from "vue-i18n";
import Vue from "vue";
import en from "./locales/en.json";
import de from "./locales/de.json";
import assetsEn from "@/assets/i18n/en.json";
import assetsDe from "@/assets/i18n/de.json";
import customersEn from "@/customers/i18n/en.json";
import authEn from "@/authentication/i18n/en.json";
import authDe from "@/authentication/i18n/de.json";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: window.navigator.language.substring(0, 2), // discard country
  fallbackLocale: "en",
  messages: {
    en: {
      ...en,
      ...assetsEn,
      ...customersEn,
      ...authEn,
    },
    de: {
      ...de,
      ...assetsDe,
      ...authDe,
    },
  },
  // silentTranslationWarn: true,
});

export default i18n;
