

















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class OsdiTextField extends Vue {
  @Prop({ type: Boolean, default: false }) editors!: boolean;
}
