











































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import DialogStore from "@/store/modules/dialog-store";
import { SidebarItems, SidebarLogoProps } from "@/../shared-ui/interfaces/sidebarProps";
import OsdiDialog from "@/../shared-ui/components/Osdi/OsdiDialog.vue";
import CustomerDetails from "@/customers/components/CustomerDetails.vue";
import CustomerAdd from "@/customers/components/CustomerAdd.vue";
import AssetDetils from "@/assets/components/AssetDetails.vue";
import DefaultLayoutSidebar from "@/../shared-ui/components/Layout/Sidebar/DefaultLayoutSidebar.vue";
import SideBarItems from "@/sidebar-items";
import Snackbar from "@/general/components/Snackbar.vue";

const dialogStore = getModule(DialogStore);

@Component({
  components: {
    DefaultLayoutSidebar,
    OsdiDialog,
    CustomerDetails,
    CustomerAdd,
    AssetDetils,
    Snackbar,
  },
})
export default class App extends Vue {
  get isLoggedIn() {
    return this.$route.name !== "Login";
  }

  get currentDialog() {
    return dialogStore.getComponentName;
  }

  get componentData() {
    return dialogStore.data;
  }

  closeDialog() {
    dialogStore.closeDialog();
  }

  get sidebarItems(): SidebarItems {
    return {
      topItems: SideBarItems(this.$i18n).top(),
      bottomItems: SideBarItems(this.$i18n).bottom(),
    };
  }

  // get languageSwitcherProps(): LanguageSwitcherProps {
  //   return {
  //     availableLanguages: this.$i18n.availableLocales,
  //     onClickHandler: (newLanguage: string) => {
  //       this.$i18n.locale = newLanguage;
  //       TrainingModule.changeLanguage(newLanguage);
  //     },
  //   };
  // }

  get sidebarLogoProps(): SidebarLogoProps {
    return {
      imgVariant: require("../shared-ui/assets/images/defaultImg.svg"),
      textVariant: undefined,
    };
  }
}
