






















































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import OsdiButton from "@/../shared-ui/components/Osdi/OsdiButton.vue";
import OsdiTable from "@/../shared-ui/components/Osdi/OsdiTable.vue";
import OsdiCloseButton from "@/../shared-ui/components/Osdi/OsdiCloseButton.vue";
import OsdiIconButton from "@/../shared-ui/components/Osdi/OsdiIconButton.vue";

import format from "date-fns/format";
import { CustomerV2, ProductLicense, ProductLicenseSave } from "../models";
import { DataTableHeader } from "vuetify";
import CustomerAddEdit from "@/customers/components/CustomerAddEdit.vue";
import LicenseAddEdit from "@/customers/components/LicenseAddEdit.vue";
import { getModule } from "vuex-module-decorators";
import CustomerStore from "@/store/modules/customer-store";
import customerService from "@/customers/customer.service";
import SnackbarModule from "@/store/modules/snackbar-store";

const notifications = getModule(SnackbarModule);

@Component({
  components: {
    OsdiCloseButton,
    OsdiButton,
    OsdiTable,
    CustomerAddEdit,
    LicenseAddEdit,
    OsdiIconButton,
  },
})
export default class CustomerDetails extends Vue {
  @Prop()
  customer!: CustomerV2 | undefined;

  private customerStore = getModule(CustomerStore);
  private showCustomerEdit = false;
  private showAddLicense = false;
  private selectedLicense: ProductLicenseSave | undefined;
  private addLicense = false;

  @Emit()
  close() {}

  mounted() {
    if (this.customer && !this.customer?.site.location) {
      this.customer.site.location = {
        address: "",
      };
    }
  }

  formatExpiry(dt: number) {
    return format(dt, "dd.MM.yyyy");
  }

  private emptyLicense: ProductLicense = {
    productName: "",
    licenseType: "",
    expiryDate: new Date().getTime() + 60 * 60 * 24 * 1000 * 365,
    orderReference: "",
    status: "",
    admin: {
      email: "",
      firstName: "",
      lastName: "",
      invitationSent: false,
      language: "en",
      userId: "",
    },
  };

  get getFormattedSignupDate() {
    return format(this.customer?.site.signupDate || Date.now(), "dd.MM.yyyy");
  }

  private licenseTableHeaders: DataTableHeader[] = [
    {
      text: "Product",
      value: "productName",
    },
    {
      text: "Type",
      value: "licenseType",
    },
    {
      text: "Expiry date",
      value: "expiryDate",
    },
    {
      text: "Order reference",
      value: "orderReference",
    },
    {
      text: "Status",
      value: "status",
    },

    {
      text: "Customer Admin",
      value: "adminEmail",
    },
    {
      text: "",
      value: "sendInvitation",
    },
    {
      text: "",
      value: "edit",
    },
  ];

  private openEditCustomer() {
    this.showCustomerEdit = true;
  }
  private closeEditCustomer() {
    this.showCustomerEdit = false;
  }
  private openEditLicense(license: ProductLicense) {
    this.addLicense = false;
    this.selectedLicense = license;
    this.showAddLicense = true;
  }
  private openAddLicense() {
    this.selectedLicense = { ...this.emptyLicense };
    this.addLicense = true;
    this.showAddLicense = true;
  }

  private async reenableUsers(){
    await customerService.reenableUsers(this.customer!.site);
  }

  private closeAddEditLicense() {
    const save = this.selectedLicense?.save;
    if (this.addLicense && this.selectedLicense != undefined && this.selectedLicense.save) {
      delete this.selectedLicense.save;
      this.customer?.site.licenses.push({ ...(this.selectedLicense as ProductLicense) });
    }
    if (save) {
      //console.log("save site", this.customer?.site.licenses ?? "");
      this.customer?.site.licenses.forEach((x: ProductLicenseSave) => {
        delete x.save;
      });
      this.customerStore.saveSite(this.customer!.site);
    }
    this.selectedLicense = undefined;
    this.showAddLicense = false;
  }

  private async sendInvitation(license: ProductLicenseSave) {
    try {
      //console.log(license);
      const result = await customerService.setLicenseAdmin(this.customer!.site, license);
      license.admin.invitationSent = result;
      if (result == false) {
        throw new Error("Failed to send invitation");
      }
      license.previousAdmin = license.admin;
      this.customerStore.saveSite(this.customer!.site);
      notifications.notifyUser({
        message: `Sent invitation to license admin`,
        type: "success",
      });
    } catch (ex) {
      notifications.notifyUser({
        message: `Could not set license admin ${ex}`,
        type: "error",
      });
    }
  }
  /**
   * @see app.vue
   **/
  public onCloseDialog() {
    this.close();
  }
}
