import logger from "@/logger";
import { getCurrentAuthenticatedUser } from "@/authentication/authentication.service";
import store from "@/store";
import type { CognitoUser } from "@aws-amplify/auth";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export enum AuthenticationState {
  forgotPassword = "forgotPassword",
  signIn = "signIn",
  requireNewPassword = "requireNewPassword",
  signedOut = "signedOut",
  signedIn = "signedIn",
}

@Module({ dynamic: true, store, name: "auth" })
export default class AuthModule extends VuexModule {
  loggedInUser: CognitoUser | null = null;
  authenticationState: AuthenticationState = AuthenticationState.signedOut;

  get isLoggedIn(): boolean {
    return this.loggedInUser !== null && this.authenticationState === AuthenticationState.signedIn;
  }

  get user(): CognitoUser | null {
    return this.loggedInUser;
  }

  get authnState(): AuthenticationState {
    return this.authenticationState;
  }

  @Mutation
  login(cognitoUser: CognitoUser | null) {
    this.loggedInUser = cognitoUser;
  }

  @Mutation
  logout() {
    this.loggedInUser = null;
    this.authenticationState = AuthenticationState.signedOut;
  }

  @Mutation
  updateAuthn(authnState: AuthenticationState) {
    this.authenticationState = authnState;
  }

  @Action
  async reloadUser() {
    try {
      const currentUser = await getCurrentAuthenticatedUser();
      this.context.commit("login", currentUser);
      this.context.commit("updateAuthn", AuthenticationState.signedIn);
    } catch (error) {
      logger.error("Could not find a logged in user.");
      this.context.commit("logout");
    }
  }
}
