











import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/modules/snackbar-store";
import { Component, Vue } from "vue-property-decorator";
const notificationStore = getModule(SnackbarModule);

@Component
export default class Snackbar extends Vue {
  get notification() {
    return notificationStore.notification;
  }

  set notification(newNotification) {
    // noop
  }
}
