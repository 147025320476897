

























































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { GreengrassAssetConnectionResult } from "../models";

@Component
export default class AssetCerts extends Vue {
  @Prop()
  assetCerts!: GreengrassAssetConnectionResult;

  private tab = null;
}
