














import Vue from "vue";
import OsdiTextField from "@/../shared-ui/components/Osdi/OsdiTextField.vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: { OsdiTextField },
})
export default class OsdiPasswordField extends Vue {
  @Prop({ default: false, type: Boolean }) show!: boolean;
}
