export const saveGlobalAssetType = `mutation SaveGlobalAssetType($assetType: SaveAssetType!) {
  saveGlobalAssetType(assetType: $assetType) {
    id
    tenant
    name
    manufacturer
    image
  }
}
`;

export const createApigatewayAssetConnection = `
mutation CreateApigatewayAssetConnection($tenant: String!, $assetId: String!) {
  createApigatewayAssetConnection(tenant: $tenant, assetId: $assetId) {
    apiUrl
    assetId
    apiKey
  }
}`;

export const createChinaAssetConnection = `
mutation CreateChinaAssetConnection($tenant: String!, $assetId: String!) {
  createChinaAssetConnection(tenant: $tenant, assetId: $assetId) {
    keysAndCertCore {
      certificateArn
      certificateId
      certificatePem
      keyPair {
        PrivateKey
        PublicKey
      }
    }
    keysAndCertDevice {
      certificateArn
      certificateId
      certificatePem
      keyPair {
        PrivateKey
        PublicKey
      }
    }
    deviceOptions {
      tenant
      siteId
      assetId
      roleAlias
      iotDataEndpoint
      iotCredEndpoint
      topics
    }
  }
}
`;

export const createGreengrassV2AssetConnection = `
mutation CreateGreengrassV2AssetConnection($tenant: String!, $assetId: String!) {
  createGreengrassV2AssetConnection(tenant: $tenant, assetId: $assetId) {
    keysAndCertCore {
      certificateArn
      certificateId
      certificatePem
      keyPair {
        PrivateKey
        PublicKey
      }
    }
    keysAndCertDevice {
      certificateArn
      certificateId
      certificatePem
      keyPair {
        PrivateKey
        PublicKey
      }
    }
    deviceOptions {
      tenant
      siteId
      assetId
      roleAlias
      iotDataEndpoint
      iotCredEndpoint
      topics
    }
  }
}
`;

export const addGreengrassAssetConnection = `
mutation AddGreengrassAssetConnection($tenant: String!, $assetId: String!, $coreName: String!) {
  addGreengrassAssetConnection(tenant: $tenant, assetId: $assetId, coreName: $coreName) {
    keysAndCertDevice {
      certificateArn
      certificateId
      certificatePem
      keyPair {
        PrivateKey
        PublicKey
      }
    }
    deviceOptions {
      tenant
      siteId
      assetId
      roleAlias
      iotDataEndpoint
      iotCredEndpoint
      topics
    }
  }
}
`;

export const disconnectGreengrassAssetConnection = `
mutation DisconnectGreengrassAssetConnection($tenant: String!, $assetId: String!) {
  disconnectGreengrassAssetConnection(tenant: $tenant, assetId: $assetId) 
}
`;

export const setMonitoringFavorite = `
mutation setMonitoringFavorite($tenant: String!, $assetId: String!, $newValue: Boolean){
  setMonitoringFavorite(tenant: $tenant, assetId: $assetId, newValue: $newValue)
}
`;

export const setMonitoringAlarm = `
mutation setMonitoringAlarm($tenant: String!, $assetId: String!, $newValue: Boolean){
  setMonitoringAlarm(tenant: $tenant, assetId: $assetId, newValue: $newValue)
}
`;
