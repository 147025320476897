
































import { Component, Prop, Vue } from "vue-property-decorator";
import { ApigatewayAssetConnectionResult } from "../models";
@Component
export default class AssetApiKey extends Vue {
  @Prop()
  assetApiKey!: ApigatewayAssetConnectionResult;
}
