
























































import { Component, Vue, Watch } from "vue-property-decorator";
import AssetCerts from "@/assets/components/AssetCerts.vue";
import AssetDelete from "@/assets/components/AssetDeleteDialog.vue";
import AssetApiKey from "@/assets/components/AssetApiKey.vue";
import OsdiButton from "@/../shared-ui/components/Osdi/OsdiButton.vue";
import OsdiSnackbar from "@/../shared-ui/components/Osdi/OsdiSnackbar.vue";

import type {
  ApigatewayAssetConnectionResult,
  Asset,
  GreengrassAssetConnectionResult,
} from "../models";
import { assetDisconnect, getCustomerDisconnectableAssets } from "@/assets/asset-service";
import { service as customerService, CustomerTenant } from "@/customers/api";

export interface FormModel {
  assetId: string;
  tenant: string;
  connectivity: "api" | "mqtt" | "china";
}

@Component({
  components: { AssetCerts, AssetApiKey, OsdiButton, OsdiSnackbar, AssetDelete },
})
export default class AssetDisconnect extends Vue {
  private payload: Partial<FormModel> = {
    assetId: undefined,
    tenant: undefined,
  };
  private requestInProgress = false;
  private customers: CustomerTenant[] = [];
  private assets: Asset[] = [];
  private snackbarMessage: string = "";
  private apiCallResult: Boolean | undefined | null = null;

  get areFieldsFilled(): boolean {
    return !!this.payload.assetId && !!this.payload.tenant;
  }

  async mounted() {
    this.customers = await customerService.fetchTenants();
  }

  private async onTenantSelected(theTenant: string) {
    this.assets = [];
    this.payload.assetId = undefined;
    this.assets = await getCustomerDisconnectableAssets(theTenant);
  }

  private async disconnectAsset() {
    const { assetId, tenant } = this.payload;
    try {
      this.requestInProgress = true;
      this.apiCallResult = await assetDisconnect(tenant!, assetId!);
      //@ts-ignore
      this.$refs.snackbar.open();
      this.snackbarMessage = "Asset disconnected.";
      this.assets = await getCustomerDisconnectableAssets(tenant!);
      this.resetFields();
    } catch (error) {
      //@ts-ignore
      this.$refs.snackbar.open();
      this.snackbarMessage = "Could not disconnect asset.";
    } finally {
      this.requestInProgress = false;
    }
  }

  private resetFields() {
    this.payload = {
      assetId: undefined,
      tenant: undefined,
    };
  }
}
