

























































































































































































import { Component, Emit, Vue, Prop } from "vue-property-decorator";
import OsdiButton from "@/../shared-ui/components/Osdi/OsdiButton.vue";
import OsdiTable from "@/../shared-ui/components/Osdi/OsdiTable.vue";
import OsdiCloseButton from "@/../shared-ui/components/Osdi/OsdiCloseButton.vue";
import OsdiTextField from "@/../shared-ui/components/Osdi/OsdiTextField.vue";
import OsdiMultilineTextField from "@/../shared-ui/components/Osdi/OsdiMultilineTextField.vue";
import OsdiMailField from "@/../shared-ui/components/Osdi/OsdiMailField.vue";

import format from "date-fns/format";
import { CustomerV2, Site, Tenant } from "../models";
import CustomerStore from "@/store/modules/customer-store";
import { getModule } from "vuex-module-decorators";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import SnackbarModule from "@/store/modules/snackbar-store";

const notifications = getModule(SnackbarModule);

@Component({
  components: {
    OsdiCloseButton,
    OsdiButton,
    OsdiTextField,
    OsdiTable,
    OsdiMailField,
    OsdiMultilineTextField,
  },
})
export default class CustomerAddEdit extends Vue {
  private customerStore = getModule(CustomerStore);
  private tenants = [{ ...this.emptyTenant }, ...this.customerStore.tenants];
  private siteStatusValues = ["", "ACTIVE", "NOT ACTIVE", "UNDEFINED"];
  @Prop()
  initialCustomer: CustomerV2 | undefined;
  customer: CustomerV2 = this.emptyCustomer;
  sites: Site[] = [];
  selectedTenant: Tenant = this.emptyTenant;
  selectedSite: Site = this.emptySite;
  private site: Site = this.emptySite;
  private tenant: Tenant = this.emptyTenant;
  private siteValid = false;
  private tenantValid = false;
  private nameRules = [(v: string) => !!v || "required"];
  private addressRules = [(v: string) => !!v || "required"];

  private tenantRules = [
    (v: string) => !!v || "required",
    (v: string) => v.length <= 120 || "max 120",
    (v: string) => /^[a-zA-Z0-9]*$/.test(v) || "only a-zA-Z0-9 allowed",
  ];
  private mailRules = [
    (v: string) => !v || (v && v.length < 100) || "max 100",
    (v: string) =>
      !v ||
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) ||
      "Invalid e-mail.",
  ];
  private requestInProgress = false;
  private siteEditMode = false;
  private tenantEditMode = false;
  private undoCustomer: CustomerV2 = this.emptyCustomer;

  async mounted() {
    this.sites = [
      this.emptySite,
      ...(await this.customerStore.getTenantSites(this.selectedTenant.tenant)),
    ];
    if (this.initialCustomer) {
      this.customer = _.cloneDeep(this.initialCustomer);
      this.selectedTenant = this.customer.tenant;
      this.tenantChanged();
      this.selectedSite = this.customer.site;
      this.fixMissingValues();
      this.siteChanged();
      this.startEditSite();
    }
  }

  private fixMissingValues() {
    if (!this.customer.site.location) {
      this.customer.site.location = {
        address: "",
      };
    }
    if (!this.customer.site.customerNumber) {
      this.customer.site.customerNumber = "";
    }
    if (!this.customer.site.signupDate) {
      this.customer.site.signupDate = Date.now();
    }
    if (!this.customer.site.status) {
      this.customer.site.status = "UNDEFINED";
    }
  }

  get emptyTenant(): Tenant {
    return {
      name: "",
      tenant: "new_tenant",
      contactEmail: "",
      additionalInfo: "",
    };
  }

  get emptySite(): Site {
    return {
      name: "",
      tenant: "",
      id: "new_site",
      status: "ACTIVE",
      location: { address: "" },
      licenses: [],
      signupDate: Date.now(),
    };
  }

  get emptyCustomer(): CustomerV2 {
    return { tenant: this.emptyTenant, site: this.emptySite };
  }

  get getFormattedSignupDate() {
    return format(this.selectedSite.signupDate || Date.now(), "dd.MM.yyyy");
  }

  get getTenants() {
    return this.tenants;
  }

  get newTenant() {
    return this.selectedTenant.tenant == "new_tenant";
  }

  get editTenant() {
    return this.selectedTenant.tenant === "new_tenant" || this.tenantEditMode;
  }

  startEditTenant() {
    this.tenantEditMode = true;
    this.undoCustomer = { tenant: { ...this.selectedTenant }, site: { ...this.emptySite } };
  }

  async tenantChanged() {
    if (this.newTenant) {
      this.tenants = [{ ...this.emptyTenant }, ...this.tenants];
    }
    this.sites = await this.customerStore.getTenantSites(this.selectedTenant.tenant);
    this.customer = { tenant: this.selectedTenant, site: this.selectedSite };
    this.tenant = this.selectedTenant;
  }

  async cancelTenant() {
    this.tenantEditMode = false;
    this.customer = { tenant: this.selectedTenant, site: this.selectedSite };

    this.tenant = this.undoCustomer.tenant;
  }

  get getSites() {
    return [{ ...this.emptySite }, ...this.sites];
  }

  startEditSite() {
    this.siteEditMode = true;
    this.undoCustomer = { tenant: { ...this.selectedTenant }, site: { ...this.selectedSite } };
  }

  get editSite() {
    return this.selectedSite.id === "new_site" || this.siteEditMode;
  }

  get newSite() {
    return this.selectedSite.id == "new_site";
  }

  async siteChanged() {
    if (this.newSite) {
      this.selectedSite.name = "";
      this.sites = [{ ...this.emptySite }, ...this.sites];
    }
    this.customer = { tenant: this.selectedTenant, site: this.selectedSite };
    this.site = this.selectedSite;
  }

  get areFieldsFilled(): boolean {
    let fieldsFilled = true;
    if (this.editTenant) {
      fieldsFilled = fieldsFilled && !!this.tenant.name;
    }

    fieldsFilled =
      fieldsFilled &&
      this.site.name != "" &&
      this.site.location?.address != "" &&
      this.site.status != "";

    return fieldsFilled;
  }

  async save() {
    this.requestInProgress = true;
    this.customer.site = this.site;
    this.customer.tenant = this.tenant;
    this.customer.site.tenant = this.tenant.tenant;
    if (this.newTenant) {
      this.customer.tenant.tenant = this.tenant.name.replace(/\s+/g, "").toLowerCase();
      //console.log("Create new tenant", this.customer.tenant);
      await this.customerStore.createNewTenant(this.customer.tenant);
      notifications.notifyUser({
        message: `Created new tenant`,
        type: "success",
      });
    } else if (this.editTenant) {
      //console.log("Save edited tenant", this.customer.tenant);
      await this.customerStore.saveTenant(this.customer.tenant);
      notifications.notifyUser({
        message: `Saved tenant information`,
        type: "success",
      });
    }
    if (this.newSite) {
      this.customer.site.id = uuid();
      this.customer.site.tenant = this.customer.tenant.tenant;
      //console.log("Save new site", this.customer.site);
      await this.customerStore.saveSite(this.customer.site);
      notifications.notifyUser({
        message: `Created new site`,
        type: "success",
      });
    } else {
      //console.log("Save edited site", this.customer.site);
      await this.customerStore.saveSite(this.customer.site);
      notifications.notifyUser({
        message: `Saved site information`,
        type: "success",
      });
    }
    this.requestInProgress = false;
  }

  async cancel() {
    this.tenantEditMode = false;
    this.site = this.undoCustomer.site;
    this.customer = { tenant: this.undoCustomer.tenant, site: this.undoCustomer.site };
    this.tenant = this.customer.tenant;
    this.site = this.customer.site;
  }

  @Emit()
  close() {
    this.cancel();
  }
}
