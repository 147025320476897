import * as mutations from "./graphql/mutations";
import * as queries from "./graphql/queries";

import { Customer, CustomerTenant, NewCustomer, ProductLicense, Site, Tenant } from "./models";

import backofficeClient from "../backoffice-client";
import { graphqlOperation } from "@aws-amplify/api-graphql";

export interface CustomerService {
  createCustomer(newCustomer: Tenant): Promise<boolean>;
  fetchCustomers(): Promise<Customer[]>;
  fetchCustomer(tenant: string): Promise<Customer>;
  fetchTenants(): Promise<Tenant[]>;
  setLicenseAdmin(site: Site, license: ProductLicense): Promise<boolean>;
  saveSite(site: Site): Promise<boolean>;
  saveTenant(tenant: Tenant): Promise<boolean>;
}

export class RemoteCustomerService implements CustomerService {
  async fetchTenants(): Promise<Tenant[]> {
    const { data } = await (backofficeClient.graphql(
      graphqlOperation(queries.getTenants)
    ) as Promise<any>);
    return data?.getTenants;
  }

  async fetchSites(): Promise<Site[]> {
    const { data } = await (backofficeClient.graphql(
      graphqlOperation(queries.getAllSites)
    ) as Promise<any>);
    return data?.getAllSites;
  }

  async fetchCustomer(tenant: string): Promise<Customer> {
    const { data } = await (backofficeClient.graphql(
      graphqlOperation(queries.getCustomer, { tenant })
    ) as Promise<any>);
    return data?.getCustomer;
  }

  async fetchCustomers(): Promise<Customer[]> {
    const { data } = await (backofficeClient.graphql(
      graphqlOperation(queries.getCustomers)
    ) as Promise<any>);
    return data?.getCustomers;
  }

  async createCustomer(newCustomer: Tenant): Promise<boolean> {
    const { data } = await (backofficeClient.graphql(
      graphqlOperation(mutations.createCustomer, { newCustomer })
    ) as Promise<any>);
    return data?.createCustomer;
  }

  async reenableUsers(site: Site): Promise<boolean> {
    const { data } = await (backofficeClient.graphql(
      graphqlOperation(mutations.reenableUsers, { site })
    ) as Promise<any>);
    return data?.reenableUsers;
  }


  async saveSite(site: Site): Promise<boolean> {
    if (!site.location.address) site.location.address = "";
    const { data } = await (backofficeClient.graphql(
      graphqlOperation(mutations.saveSite, { site })
    ) as Promise<any>);
    return data?.site;
  }

  async saveTenant(tenant: Tenant): Promise<boolean> {
    const { data } = await (backofficeClient.graphql(
      graphqlOperation(mutations.saveTenant, { tenant })
    ) as Promise<any>);
    return data?.tenant;
  }

  async setLicenseAdmin(site: Site, license: ProductLicense): Promise<boolean> {
    const { data } = await (backofficeClient.graphql(
      graphqlOperation(mutations.setLicenseAdmin, { site, license })
    ) as Promise<any>);
    return data?.setLicenseAdmin;
  }
  async getPresignedurlforApp(): Promise<string> {
    const { data } = await (backofficeClient.graphql(
      graphqlOperation(queries.getPresignedUrlApp)
    ) as Promise<any>);
    return data?.getPresignedUrlApp;
  }
}

export default new RemoteCustomerService();
