























import Auth from "@aws-amplify/auth";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import AuthModule, { AuthenticationState } from "@/authentication/auth-store";
import logger from "@/logger";
import OsdiButton from "@/../shared-ui/components/Osdi/OsdiButton.vue";
import OsdiMailField from "@/../shared-ui/components/Osdi/OsdiMailField.vue";
import OsdiPasswordField from "@/../shared-ui/components/Osdi/OsdiPasswordField.vue";
import OsdiTextField from "@/../shared-ui/components/Osdi/OsdiTextField.vue";
const authStore = getModule(AuthModule);

@Component({
  components: { OsdiButton, OsdiTextField, OsdiMailField, OsdiPasswordField },
})
export default class Login extends Vue {
  private username = "";
  private password = "";
  private valid = true;
  private error = "";

  login() {
    this.doLogin();
  }

  async doLogin() {
    try {
      const cognitoUser = await Auth.signIn(this.username.toLowerCase(), this.password);
      logger.info("sign in successful");

      /**
       * original logic:
       * @see https://github.com/aws-amplify/amplify-js/blob/master/packages/aws-amplify-vue/src/components/authenticator/SignIn.vue
       */
      if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        logger.info("require new password");
        authStore.updateAuthn(AuthenticationState.requireNewPassword);
      } else {
        authStore.updateAuthn(AuthenticationState.signedIn);
      }

      authStore.login(cognitoUser);
      this.error = "";
    } catch (error) {
      authStore.login(null);
      logger.error(error);
      this.error = this.$t("auth.errors.loginFailed").toString();
    }
  }

  forgotPassword() {
    authStore.updateAuthn(AuthenticationState.forgotPassword);
  }
}
