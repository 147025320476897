




































import { Component, Prop, Vue } from "vue-property-decorator";
import { SidebarItem } from "../../../interfaces/sidebarProps";
import { LanguageSwitcherProps } from "../../../interfaces/language-switcherProps";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher.vue";
@Component({ components: { LanguageSwitcher } })
export default class DefaultLayoutSidebarItem extends Vue {
  @Prop({ required: true }) readonly item?: SidebarItem;
  @Prop() languageSwitcherProps?: LanguageSwitcherProps;

  get attrs(): object {
    return {
      "input-value": this.item?.isActive,
      to: this.item?.to,
    };
  }

  get listeners(): object {
    return {
      click: this.item?.onClick || (() => undefined),
    };
  }
}
