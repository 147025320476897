































import { Component, Emit, VModel, Vue } from "vue-property-decorator";
import { AssetType } from "../models";
import { saveGlobalAssetType } from "../asset-service";
import OsdiTextField from "@/../shared-ui/components/Osdi/OsdiTextField.vue";
import OsdiButton from "@/../shared-ui/components/Osdi/OsdiButton.vue";
import OsdiTable from "@/../shared-ui/components/Osdi/OsdiTable.vue";

@Component({
  components: { OsdiTable, OsdiButton, OsdiTextField },
})
export default class AssetDetails extends Vue {
  private valid = false;
  private nameRules = [(v: string) => !!v || "required"];
  private manufacturerRules = [(v: string) => !!v || "required"];

  @VModel()
  editedAssetType!: AssetType;

  async saveAssetType() {
    await saveGlobalAssetType(this.editedAssetType!);
    this.close();
  }

  @Emit()
  close() {}

  /**
   * @see app.vue
   **/
  public onCloseDialog() {
    this.close();
  }
}
