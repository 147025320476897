


































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class LanguageSwitcher extends Vue {
  @Prop() readonly availableLanguages?: string[];
  @Prop() readonly languageHandler?: (language: string) => void;
  @Prop({ default: false }) readonly sidebarIsActive?: boolean;

  private currentLanguage = this.$i18n.locale;
  private languages: string[] = [];
  private selectedIndex = this.languages.findIndex((l) => l === this.currentLanguage);
  private panels: Array<boolean> = [];

  created() {
    this.languages = this.availableLanguages ?? [];
    this.resetPanels();
  }

  @Watch("sidebarIsActive")
  sidebarIsActiveChanged(status: boolean) {
    if (!status) {
      this.resetPanels();
    }
  }

  resetPanels() {
    this.panels = new Array(this.languages).map(() => false);
  }

  private changeLanguage(language: string) {
    this.currentLanguage = language;
    if (this.languageHandler) {
      this.languageHandler(language);
    }
  }
}
