import { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import graphqlClient from "../backoffice-client";
import * as mutations from "./graphql/mutations";
import * as queries from "./graphql/queries";
import {
  ApigatewayAssetConnectionResult,
  Asset,
  CoreInfo,
  AssetType,
  GreengrassAssetConnectionResult,
  GreengrassAddAssetConnectionResult,
  ResourceIdentifier,
  AssetMonitoringInfo,
} from "./models";

export async function searchAwsResources(
  theTenant: string,
  theAsset?: string
): Promise<ResourceIdentifier[]> {
  const { data } = await (graphqlClient.graphql(
    graphqlOperation(queries.searchAwsResources, {
      tenant: theTenant,
      assetId: theAsset,
    })
  ) as Promise<
    GraphQLResult<{
      searchAwsResources: ResourceIdentifier[];
      searchChinaAwsResources: ResourceIdentifier[];
    }>
  >);
  const searchAwsResources = data?.searchAwsResources ?? [];
  const searchChinaAwsResources = data?.searchChinaAwsResources ?? [];
  return [...searchAwsResources, ...searchChinaAwsResources];
}

export async function getGlobalAssetTypes(): Promise<AssetType[] | undefined> {
  const { data } = await (graphqlClient.graphql(
    graphqlOperation(queries.getGlobalAssetTypes)
  ) as Promise<GraphQLResult<{ getGlobalAssetTypes: AssetType[] }>>);
  return data?.getGlobalAssetTypes;
}

export async function saveGlobalAssetType(assetType: AssetType): Promise<AssetType | undefined> {
  const { data } = await (graphqlClient.graphql(
    graphqlOperation(mutations.saveGlobalAssetType, { assetType })
  ) as Promise<GraphQLResult<{ saveGlobalAssetType: AssetType }>>);
  return data?.saveGlobalAssetType || undefined;
}

export async function getCustomerConnectableAssets(theTenant: string): Promise<Asset[]> {
  const { data } = await (graphqlClient.graphql(
    graphqlOperation(queries.getCustomerConnectableAssets, { tenant: theTenant })
  ) as Promise<GraphQLResult<{ getCustomerConnectableAssets: Asset[] }>>);
  return data?.getCustomerConnectableAssets ?? [];
}

export async function getCustomerConnectableGreengrass(theTenant: string): Promise<CoreInfo[]> {
  const { data } = await (graphqlClient.graphql(
    graphqlOperation(queries.getCustomerConnectableGreengrass, {
      tenant: theTenant,
    })
  ) as Promise<GraphQLResult<{ getCustomerConnectableGreengrass: CoreInfo[] }>>);
  return data?.getCustomerConnectableGreengrass ?? [];
}

export async function getCustomerDisconnectableAssets(theTenant: string): Promise<Asset[]> {
  const { data } = await (graphqlClient.graphql(
    graphqlOperation(queries.getCustomerDisconnectableAssets, { tenant: theTenant })
  ) as Promise<GraphQLResult<{ getCustomerDisconnectableAssets: Asset[] }>>);
  return data?.getCustomerDisconnectableAssets ?? [];
}

export async function connectAssetV2(
  tenant: string,
  assetId: string
): Promise<GreengrassAssetConnectionResult | undefined> {
  const { data } = await (graphqlClient.graphql(
    graphqlOperation(mutations.createGreengrassV2AssetConnection, { tenant, assetId })
  ) as Promise<
    GraphQLResult<{ createGreengrassV2AssetConnection: GreengrassAssetConnectionResult }>
  >);
  return data?.createGreengrassV2AssetConnection;
}

export async function connectAssetAddGreengrass(
  tenant: string,
  assetId: string,
  coreName: string
): Promise<GreengrassAddAssetConnectionResult | undefined> {
  const { data } = await (graphqlClient.graphql(
    graphqlOperation(mutations.addGreengrassAssetConnection, { tenant, assetId, coreName })
  ) as Promise<
    GraphQLResult<{ addGreengrassAssetConnection: GreengrassAddAssetConnectionResult }>
  >);
  return data?.addGreengrassAssetConnection;
}

export async function connectChinaAsset(
  tenant: string,
  assetId: string
): Promise<GreengrassAssetConnectionResult | undefined> {
  const { data } = await (graphqlClient.graphql(
    graphqlOperation(mutations.createChinaAssetConnection, { tenant, assetId })
  ) as Promise<GraphQLResult<{ createChinaAssetConnection: GreengrassAssetConnectionResult }>>);
  return data?.createChinaAssetConnection;
}

export async function connectAssetApi(
  tenant: string,
  assetId: string
): Promise<ApigatewayAssetConnectionResult | undefined> {
  const { data } = await (graphqlClient.graphql(
    graphqlOperation(mutations.createApigatewayAssetConnection, { tenant, assetId })
  ) as Promise<
    GraphQLResult<{ createApigatewayAssetConnection: ApigatewayAssetConnectionResult }>
  >);
  return data?.createApigatewayAssetConnection;
}

export async function assetDisconnect(
  tenant: string,
  assetId: string
): Promise<Boolean | undefined> {
  const { data } = await (graphqlClient.graphql(
    graphqlOperation(mutations.disconnectGreengrassAssetConnection, { tenant, assetId })
  ) as Promise<GraphQLResult<{ disconnectGreengrassAssetConnection: boolean }>>);
  return data?.disconnectGreengrassAssetConnection;
}

//#region Monitoring
export async function getAssetsMonitoringInfo(): Promise<AssetMonitoringInfo[] | undefined> {
  try {
    const { data } = await (graphqlClient.graphql(
      graphqlOperation(queries.getAssetsMonitoringInfo)
    ) as Promise<GraphQLResult<{ getAssetsMonitoringInfo: AssetMonitoringInfo[] }>>);
    //remove assets withour SiteId or AssetId
    if (data?.getAssetsMonitoringInfo) {
      return data?.getAssetsMonitoringInfo.filter(
        (asset) => asset.siteId !== null && asset.assetId !== null
      );
    }
    return data?.getAssetsMonitoringInfo;
  } catch (error) {
    throw Error(`Error in getAssetsMonitoringInfo ${error}`);
  }
}

export async function setMonitoringFavorite(
  tenant: string,
  assetId: string,
  newValue: boolean
): Promise<Boolean | undefined> {
  try {
    const { data } = await (graphqlClient.graphql(
      graphqlOperation(mutations.setMonitoringFavorite, { tenant, assetId, newValue })
    ) as Promise<GraphQLResult<{ setMonitoringFavorite: boolean }>>);
    return data?.setMonitoringFavorite;
  } catch (error) {
    throw Error(`Error in setMonitoringFavorite ${error}`);
  }
}

export async function setMonitoringAlarm(
  tenant: string,
  assetId: string,
  newValue: boolean
): Promise<Boolean | undefined> {
  try {
    const { data } = await (graphqlClient.graphql(
      graphqlOperation(mutations.setMonitoringAlarm, { tenant, assetId, newValue })
    ) as Promise<GraphQLResult<{ setMonitoringAlarm: boolean }>>);
    return data?.setMonitoringAlarm;
  } catch (error) {
    throw Error(`Error in setMonitoringAlarm ${error}`);
  }
}
//#endregion Monitoring
