








































import Vue from "vue";
import { Component, Emit } from "vue-property-decorator";
import OsdiCloseButton from "@/../shared-ui/components/Osdi/OsdiCloseButton.vue";
import DialogStore from "@/store/modules/dialog-store";
import { getModule } from "vuex-module-decorators";
const dialogStore = getModule(DialogStore);

@Component({
  components: { OsdiCloseButton },
})
export default class OsdiDialog extends Vue {
  get showDialog() {
    return dialogStore.isDialogVisible;
  }

  get customDialog() {
    return dialogStore.customDialog;
  }

  @Emit()
  close() {}
}
