






























































import { Component, Vue, Prop } from "vue-property-decorator";
import { AssetMonitoringInfo } from "../models";
import MonitoringStore from "@/store/modules/monitoring-store";
import { getModule } from "vuex-module-decorators";

@Component
export default class Monitoring extends Vue {
  headers = [
    { text: "Tenant", value: "tenant" },
    { text: "Site-Id", value: "siteId" },
    { text: "Asset-Id", value: "assetId" },
    { text: "Machine name", value: "name" },
    { text: "Last online", value: "lastOnline" },
    { text: "prod change", value: "lastProductionChange" },
    { text: "S3", value: "ingestionStatus.s3" },
    { text: "Dynamo", value: "ingestionStatus.dynamo" },
    { text: "Influx", value: "ingestionStatus.influx" },
    { text: "analog Data", value: "lastVibrationChange" },
    { text: "OPC UA", value: "lastOpcUaChange" },
    { text: "Favorite", value: "monitoringFavoriteUser" },
    { text: "Alarms", value: "monitoringAlarmUser" },
  ];
  monitoringStore = getModule(MonitoringStore);

  async mounted() {
    this.monitoringStore.GetAssetsMonitoringInfo();
  }

  get items() {
    return this.monitoringStore.assets.map((item) => ({
      ...item,
      lastOnline: this.formatTimestamp(item.lastOnline),
      lastOpcUaChange: this.formatTimestamp(item.lastOpcUaChange),
      lastProductionChange: this.formatTimestamp(item.lastProductionChange),
      lastVibrationChange: this.formatTimestamp(item.lastVibrationChange),
    }));
  }

  formatTimestamp(timestamp: number | undefined) {
    if (!timestamp) return "";
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  async toggleFavorite(asset: AssetMonitoringInfo) {
    if (asset?.tenant && asset.assetId) {
      this.monitoringStore.SetMonitoringFavorite({
        tenant: asset.tenant,
        assetId: asset.assetId,
        newValue: !(asset.monitoringFavoriteUser ?? false),
      });
    } else
      throw Error(
        `Error toggleFavorite: assetId: ${asset.assetId} or tenant: ${asset.tenant} not set`
      );
  }
  toggleAlarm(asset: AssetMonitoringInfo) {
    if (asset?.tenant && asset.assetId) {
      this.monitoringStore.SetMonitoringAlarm({
        tenant: asset.tenant,
        assetId: asset.assetId,
        newValue: !(asset.monitoringAlarmUser ?? false),
      });
    } else
      throw Error(
        `Error toggleAlarm: assetId: ${asset.assetId} or tenant: ${asset.tenant} not set`
      );
  }
}
