











































import { Component, Vue, Emit } from "vue-property-decorator";
import customerService from "@/customers/customer.service";
import { NewCustomer } from "../models";
import logger from "@/logger";
import OsdiButton from "@/../shared-ui/components/Osdi/OsdiButton.vue";
import OsdiCloseButton from "@/../shared-ui/components/Osdi/OsdiCloseButton.vue";
import OsdiMailField from "@/../shared-ui/components/Osdi/OsdiMailField.vue";
import OsdiTextField from "@/../shared-ui/components/Osdi/OsdiTextField.vue";
import OsdiSnackbar from "@/../shared-ui/components/Osdi/OsdiSnackbar.vue";
@Component({
  components: { OsdiButton, OsdiCloseButton, OsdiTextField, OsdiMailField, OsdiSnackbar },
})
export default class CustomerAdd extends Vue {
  private requestInProgress = false;
  private newCustomer: NewCustomer = this.emptyNewCustomer;
  private snackbarMessage: string = "";
  private snackbarVisibility: boolean = false;
  private valid = false;
  private nameRules = [(v: string) => !!v || "required"];
  private tenantRules = [
    (v: string) => !!v || "required",
    (v: string) => v.length <= 120 || "max 120",
    (v: string) => /^[a-zA-Z0-9]*$/.test(v) || "only a-zA-Z0-9 allowed",
  ];
  private mailRules = [
    (v: string) => !!v || "required",
    (v: string) => v.length < 100 || "max 100",
    (v: string) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Invalid e-mail.",
  ];

  get emptyNewCustomer(): NewCustomer {
    return {
      name: "",
      tenant: "",
      initialAdminEmail: "",
      address: "",
    };
  }

  get areFieldsFilled(): boolean {
    return !!this.newCustomer.initialAdminEmail && !!this.newCustomer.tenant;
  }

  @Emit()
  close() {}

  /**
   * @see app.vue
   **/
  public onCloseDialog() {
    this.close();
  }

  private async createUser() {
    try {
      this.requestInProgress = true;
      await customerService.createCustomer(this.newCustomer);
      this.close();
      //@ts-ignore
      this.$refs.snackbar.open();
      this.snackbarMessage = this.$t("customers.customerAdd.actions.create.successful").toString();
    } catch (error) {
      logger.error("Could not create customer.", error);
      //@ts-ignore
      this.$refs.snackbar.open();
      this.snackbarMessage = "Could not create customer.";
    } finally {
      this.requestInProgress = false;
    }
  }
}
