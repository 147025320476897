import {
  Action,
  Module as VueXModule,
  Mutation,
  MutationAction,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";
import { CustomerV2, Site, Tenant } from "@/customers/models";
import customerService from "@/customers/customer.service";

function calcCustomers(tenants: Tenant[], sites: Site[]) {
  if (!tenants || !sites) {
    return [];
  }
  const customers: CustomerV2[] = [];
  sites.forEach((site) => {
    const siteTenant = tenants.find((t) => {
      return t.tenant == site.tenant;
    });
    if (siteTenant && site) customers.push({ tenant: siteTenant, site: site });
  });

  return customers;
}

@VueXModule({ dynamic: true, store, name: "customer-store" })
export default class CustomerStore extends VuexModule {
  tenants: Tenant[] = [];
  customers: CustomerV2[] = [];
  public sites: Site[] = [];

  @Action
  getTenantSites(tenantId: string) {
    const sites = this.sites.filter((s) => {
      return s.tenant == tenantId;
    });
    return sites;
  }

  @MutationAction({ mutate: ["tenants"], rawError: true })
  private async fetchTenants() {
    return { tenants: await customerService.fetchTenants() };
  }

  @MutationAction({ mutate: ["sites"], rawError: true })
  async fetchSites() {
    let sites = await customerService.fetchSites();
    sites = sites.map((x) => {
      if (!x.licenses) {
        return { ...x, licenses: [] };
      }
      return x;
    });
    return { sites: sites };
  }

  @Mutation
  setCustomers(customers: CustomerV2[]) {
    this.customers = customers;
  }

  @Mutation
  setTenant(tenant: Tenant) {
    const oldTenant = this.tenants.find((t) => {
      return t.tenant == tenant.tenant;
    });
    if (!oldTenant) {
      this.tenants.push(tenant);
    } else {
      Object.assign(oldTenant, tenant);
    }

    this.customers = calcCustomers(this.tenants, this.sites);
  }

  @Mutation
  setSite(site: Site) {
    const oldSite = this.sites.find((s) => {
      return s.id == site.id;
    });
    if (!oldSite) {
      this.sites.push(site);
    } else {
      Object.assign(oldSite, site);
    }
    this.customers = calcCustomers(this.tenants, this.sites);
  }

  @Action({ commit: "setCustomers" })
  async fetchCustomers() {
    await this.fetchSites();
    await this.fetchTenants();
    return calcCustomers(this.tenants, this.sites);
  }

  @Action({ commit: "setSite" })
  async saveSite(site: Site) {
    await customerService.saveSite(site);
    return site;
  }

  @Action({ commit: "setTenant" })
  async saveTenant(tenant: Tenant) {
    await customerService.saveTenant(tenant);
    return tenant;
  }

  @Action({ commit: "setTenant" })
  async createNewTenant(tenant: Tenant) {
    await customerService.createCustomer(tenant);
    return tenant;
  }
}
