import logger from "@/logger";
import router from "@/router";
import { languages } from "@/general/languages";
import Auth, { CognitoUser } from "@aws-amplify/auth";

export async function getCurrentAuthenticatedUser(): Promise<CognitoUser | any> {
  return Auth.currentAuthenticatedUser();
}

export async function getTenant(): Promise<string> {
  const session = await Auth.currentSession();
  return session.getIdToken().payload["custom:tenant"] as string;
}

export async function hasPermission(permission: string): Promise<boolean> {
  const session = await Auth.currentSession();
  const permissions = Object.keys(session.getIdToken().payload)
    .filter((items) => items.startsWith("permission:"))
    .map((permission) => permission.substr(11));

  if (permissions.indexOf(permission) === -1) {
    return false;
  }

  return true;
}

export async function getAllStatus(element: string, language: languages): Promise<string[]> {
  const session = await Auth.currentSession();
  const permissions = Object.keys(session.getIdToken().payload)
    .filter(
      (items) =>
        items.startsWith(`permission:update${element}:status:`) ||
        items.startsWith(`permission:create${element}:status:`)
    )
    .map((permission) => permission.substr(17 + element.length + 8))
    .filter((items) => items.split(":")[1] === language)
    .map((items) => items.split(":")[0]);

  return permissions;
}

export async function getJwToken(): Promise<string> {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
}

export function signOut() {
  return Auth.signOut()
    .then(() => router.push("/login/CustomerList"))
    .catch((error) => {
      logger.error("Unable to log user out.", error);
    });
}
