import AssetDisconnect from "@/assets/components/AssetDisconnect.vue";
import AssetTypeList from "@/assets/components/AssetTypeList.vue";
import ConnectAsset from "@/assets/components/ConnectAsset.vue";
import InfrastructureResources from "@/assets/components/InfrastructureResources.vue";
import Monitoring from "@/assets/components/Monitoring.vue";
import Authentication from "@/authentication/Authentication.vue";
import CustomerList from "@/customers/components/CustomerList.vue";
import Container from "@/general/components/Container.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { getModule } from "vuex-module-decorators";
import AuthModule from "./authentication/auth-store";

const authStore = getModule(AuthModule);

Vue.use(VueRouter);

export const enum ROUTES {
  AssetTypeList = "AssetTypeList",
  ConnectAsset = "ConnectAsset",
  AssetDisconnect = "AssetDisconnect",
  CustomerList = "CustomerList",
  InfrastructureResources = "InfrastructureResources",
  Monitoring = "Monitoring",
}

const routes: RouteConfig[] = [
  { path: "/", redirect: "/customers", meta: { requireAuth: true } },
  {
    name: ROUTES.CustomerList,
    path: "/customers",
    component: CustomerList,
    meta: { requireAuth: true },
  },
  {
    name: ROUTES.AssetTypeList,
    path: "/assettypes",
    component: AssetTypeList,
    meta: { requireAuth: true },
    children: [],
  },
  {
    name: ROUTES.ConnectAsset,
    path: "/assets",
    component: ConnectAsset,
    meta: { requireAuth: true },
  },
  {
    name: ROUTES.InfrastructureResources,
    path: "/infrastrucure-resources",
    component: InfrastructureResources,
    meta: { requireAuth: true },
  },
  {
    name: ROUTES.AssetDisconnect,
    path: "/assetdisconnect",
    component: AssetDisconnect,
    meta: { requireAuth: true },
  },
  {
    name: ROUTES.Monitoring,
    path: "/monitoring",
    component: Monitoring,
    meta: { requireAuth: true },
  },
  {
    path: "/login/:to",
    name: "Login",
    component: Authentication,
  },
];
const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, _, next) => {
  const { requireAuth } = to.meta;
  if (requireAuth) {
    await authStore.reloadUser();
    if (authStore.isLoggedIn) {
      return next();
    } else {
      const toAfterLogin = to.name;
      return next({ name: "Login", params: { to: toAfterLogin || "Dashboard" } });
    }
  } else {
    return next();
  }
});

export default router;
