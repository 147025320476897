export const createCustomer = `
 mutation CreateCustomer($newCustomer:TenantInput!) {
  createCustomer(newCustomer: $newCustomer)
}
`;

export const saveSite = `
 mutation SaveSite($site:SiteInput!) {
  saveSite(site: $site) {
    id
  }
}
`;

export const reenableUsers = `
 mutation reenableUsers($site:SiteInput!) {
  reenableUsers(site: $site)
}
`;

export const saveTenant = `
 mutation SaveTenant($tenant:TenantInput!) {
  saveTenant(tenant: $tenant){
    tenant
  }
}
`;

export const setLicenseAdmin = `
 mutation SetLicenseAdmin($site: SiteInput!, $license:ProductLicenseInput!) {
  setLicenseAdmin(site: $site, license: $license)
}`;
