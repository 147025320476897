













import { v4 as uuid } from "uuid";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { DataTableHeader } from "vuetify";
import { getGlobalAssetTypes } from "../asset-service";
import { AssetType } from "../models";
import OsdiButton from "@/../shared-ui/components/Osdi/OsdiButton.vue";
import OsdiTable from "@/../shared-ui/components/Osdi/OsdiTable.vue";
import DialogStore from "@/store/modules/dialog-store";

const dialogStore = getModule(DialogStore);

@Component({
  components: { OsdiTable, OsdiButton },
})
export default class AssetTypeList extends Vue {
  private tableHeaders: DataTableHeader[] = [
    {
      text: this.$t("assets.assetTypes.name").toString(),
      value: "name",
    },
    {
      text: this.$t("assets.assetTypes.manufacturer").toString(),
      value: "manufacturer",
    },
  ];
  private assetTypes: AssetType[] = [];

  editedAssetType: AssetType | null = null;

  isModalOpen = false;

  async mounted() {
    await this.loadAssetTypes();
  }

  async loadAssetTypes() {
    this.assetTypes = (await getGlobalAssetTypes()) ?? [];
  }

  async openDialog(assetType: AssetType | undefined = undefined) {
    await dialogStore.openDialog({
      componentName: "asset-detils",
      data: assetType || {
        id: uuid(),
        name: "",
        tenant: "global",
        manufacturer: "",
      },
    });
  }
}
