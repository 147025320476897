










































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import OsdiButton from "@/../shared-ui/components/Osdi/OsdiButton.vue";
import OsdiTable from "@/../shared-ui/components/Osdi/OsdiTable.vue";
import OsdiCloseButton from "@/../shared-ui/components/Osdi/OsdiCloseButton.vue";
import OsdiTextField from "@/../shared-ui/components/Osdi/OsdiTextField.vue";
import OsdiMultilineTextField from "@/../shared-ui/components/Osdi/OsdiMultilineTextField.vue";
import OsdiMailField from "@/../shared-ui/components/Osdi/OsdiMailField.vue";

import format from "date-fns/format";
import { CustomerV2, ProductLicense, ProductLicenseSave } from "../models";

function emptyLicense(): ProductLicense {
  return {
    productName: "",
    licenseType: "",
    expiryDate: Date.now(),
    orderReference: "",
    status: "Active",
    admin: {
      email: "",
      firstName: "",
      lastName: "",
      invitationSent: false,
      language: "en",
      userId: "",
    },
  };
}

@Component({
  components: {
    OsdiCloseButton,
    OsdiButton,
    OsdiTextField,
    OsdiTable,
    OsdiMailField,
    OsdiMultilineTextField,
  },
})
export default class LicenseAddEdit extends Vue {
  @Prop({ default: emptyLicense })
  license!: ProductLicenseSave;
  @Prop()
  customer: CustomerV2 | undefined;

  private saveLicense: ProductLicenseSave = { ...this.license };
  private licenseTypes: string[] = [];
  private conditionMonitorLicenses = ["Test license", "Full license"];
  private alvaLicenses = ["Test license", "Full license", "Pharma Technology Content"];
  private qedLicenses = ["Internal"];
  private pdmLicenses = ["Internal"];
  private licenseStatuses = ["Active", "Expired", "Deactivated"];
  private languages = ["en", "de", "es", "zh"];
  private date = "";
  private datePickerOpen = false;

  get productNames() {
    let productNames = ["Alva", "ConditionMonitor"];
    if (this.customer?.tenant.tenant.toLowerCase() == "fettecompacting") {
      productNames.push("QED", "PredictiveMaintenance");
    }
    return productNames;
  }

  private mailRules = [
    (v: string) => !!v || "required",
    (v: string) => v.length < 100 || "max 100",
    (v: string) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) ||
      v == "" ||
      "Invalid e-mail.",
  ];
  private orderReferenceRules = [(v: string) => !!v || "required"];
  private selectRules = [(v: string) => !!v || "required"];
  private nameRules = [(v: string) => !!v || "required"];

  valid: boolean = false;

  mounted() {
    this.saveLicense = { ...this.license };
    this.date = this.formatExpiry(this.saveLicense.expiryDate);
    if (
      this.saveLicense.productName == "Alva" &&
      !this.alvaLicenses.find((x) => x == this.saveLicense.licenseType)
    ) {
      this.alvaLicenses.push(this.saveLicense.licenseType);
    }
    if (
      this.saveLicense.productName == "ConditionMonitor" &&
      !this.conditionMonitorLicenses.find((x) => x == this.saveLicense.licenseType)
    ) {
      this.conditionMonitorLicenses.push(this.saveLicense.licenseType);
    }
    this.productChanged();
  }

  formatExpiry(dt: number) {
    return format(dt, "yyyy-MM-dd");
  }

  productChanged() {
    if (this.saveLicense.productName == "Alva") {
      this.licenseTypes = this.alvaLicenses;
    } else if (this.saveLicense.productName == "ConditionMonitor") {
      this.licenseTypes = this.conditionMonitorLicenses;
    } else if (this.saveLicense.productName == "QED") {
      this.licenseTypes = this.qedLicenses;
    } else if (this.saveLicense.productName == "PredictiveMaintenance") {
      this.licenseTypes = this.pdmLicenses;
    } else this.licenseTypes = [];
  }

  statusChanged() {}

  expiryDateChanged() {
    this.saveLicense.expiryDate = Date.parse(this.date);
  }

  save() {
    if (this.saveLicense.admin.email != this.license.admin.email) {
      this.saveLicense.previousAdmin = this.license.admin;
      this.saveLicense.admin.invitationSent = false;
    }
    this.license.save = true;
    Object.assign(this.license, this.saveLicense);
    this.close();
  }

  @Emit()
  close() {}
}
