import { Module as VueXModule, MutationAction, VuexModule } from "vuex-module-decorators";
import store from "@/store";

@VueXModule({ dynamic: true, store, name: "dialog-store-name" })
export default class DialogStore extends VuexModule {
  visibilityState: boolean = false;
  componentName: string = "";
  data: any = null;
  customDialog: boolean = false;

  get isDialogVisible() {
    return this.visibilityState;
  }

  get getComponentName() {
    return this.componentName;
  }

  @MutationAction
  async openDialog(content: { componentName: string; data: any; customDialog?: boolean }) {
    return {
      visibilityState: true,
      componentName: content.componentName,
      data: content.data,
      customDialog: content.customDialog ?? false,
    };
  }

  @MutationAction
  async closeDialog() {
    return {
      visibilityState: false,
      componentName: "",
      data: null,
      customDialog: false,
    };
  }
}
