

















import OsdiTable from "@/../shared-ui/components/Osdi/OsdiTable.vue";
import TenantAssetSelector from "@/assets/components/TenantAssetSelector.vue";
import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { searchAwsResources } from "../asset-service";
import { ResourceIdentifier } from "../models";
@Component({
  components: { OsdiTable, TenantAssetSelector },
})
export default class InfrastructureResources extends Vue {
  private selectedTenant: string = "";
  readonly tableHeaders: DataTableHeader[] = [
    {
      text: this.$t("infrastructure-resources.identifier.arn").toString(),
      value: "ResourceArn",
    },
    {
      text: this.$t("infrastructure-resources.identifier.type").toString(),
      value: "ResourceType",
    },
  ];
  awsResources: ResourceIdentifier[] = [];

  async tenantSelected(theTenant: string) {
    this.selectedTenant = theTenant;
    this.awsResources = await searchAwsResources(theTenant);
  }

  async assetSelected(theAsset: string) {
    this.awsResources = await searchAwsResources(this.selectedTenant, theAsset);
  }
}
